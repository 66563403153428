import type { FC } from 'react'

import { Layout } from '../components/Layout'
import { SEO } from '../components/Seo'
import { isOpenSource } from '../constants/config'
import { projects } from '../constants/routes'
import { defaultBranchPath } from '../constants/strings'
import { useProjects } from '../hooks/useProjects'

const Projects: FC = () => {
  const { filePath, html, sourceUrl } = useProjects()

  return (
    <Layout>
      <SEO keywords={['projects']} pathname={projects} title="All projects" />

      <section dangerouslySetInnerHTML={{ __html: html }} />

      {isOpenSource && (
        <p>
          <a
            href={`${sourceUrl}${defaultBranchPath}${filePath}`}
            rel="noopener noreferrer"
          >
            Suggest a change
          </a>
        </p>
      )}
    </Layout>
  )
}

// eslint-disable-next-line import/no-default-export
export default Projects
